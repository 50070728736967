var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('item-edit-view',{attrs:{"title":"Симптом","subtitle":_vm.item ? _vm.item.name : null,"loading":_vm.loading},scopedSlots:_vm._u([(_vm.item && !_vm.loading)?{key:"right",fn:function(){return [_c('v-btn',{attrs:{"icon":"","title":"Скопировать"},on:{"click":_vm.copy}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-copy")])],1),_c('v-btn',{staticClass:"mr-4",attrs:{"href":_vm.previewUrl,"target":"_blank","title":"Превью","icon":""}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{attrs:{"right":"","color":"success","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Сохранить ")],1)]},proxy:true}:null],null,true)},[_c('v-card',[(!_vm.loading && _vm.item)?_c('v-form',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"info"},[_vm._v("Инфо")]),_c('v-tab',{key:"photo"},[_vm._v("Фото")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"info"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.statuses,"label":"Статус"},model:{value:(_vm.item.status),callback:function ($$v) {_vm.$set(_vm.item, "status", $$v)},expression:"item.status"}}),_c('validation-provider',{attrs:{"name":"Название","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Название","error-messages":errors,"required":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Uri","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('slug-editor',{staticClass:"mb-12 mt-4",attrs:{"name":"uri","input":_vm.item.name,"label":"URL-адрес статьи","placeholder":"URL-адрес статьи","error":errors[0],"persistent-hint":"","hide-details":"auto","path":_vm.publicUrl},model:{value:(_vm.item.slug),callback:function ($$v) {_vm.$set(_vm.item, "slug", $$v)},expression:"item.slug"}})]}}],null,true)}),_c('div',[_c('div',{staticClass:"body-1"},[_vm._v("Описание")]),_c('validation-provider',{attrs:{"name":"Описание","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-input',{attrs:{"value":_vm.item.description,"error-messages":errors,"required":""}},[_c('quill',{attrs:{"options":_vm.quillToolbar},model:{value:(_vm.item.description),callback:function ($$v) {_vm.$set(_vm.item, "description", $$v)},expression:"item.description"}})],1)]}}],null,true)})],1)],1)],1)],1),_c('v-tab-item',{key:"photo"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-btn',{staticClass:"mr-1",on:{"click":_vm.choosePhoto}},[_vm._v(_vm._s(_vm.item.photoId ? 'Изменить' : 'Выбрать'))]),(_vm.item.photo)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.removePhoto}},[_vm._v("Удалить")]):_vm._e(),(_vm.item.photo)?_c('v-img',{staticClass:"mt-2",attrs:{"src":_vm.item.photo.url,"max-width":"1024","contain":""}}):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
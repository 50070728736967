export const DRAFT = 'draft'
export const PUBLISHED = 'published'

export const STATUS_LABELS = [
  { value: DRAFT, text: 'Черновик' },
  { value: PUBLISHED, text: 'Опубликовано' }
]

export default {
  DRAFT,
  PUBLISHED
}

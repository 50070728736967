<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <item-edit-view
      title="Симптом"
      :subtitle="item ? item.name : null"
      :loading="loading"
    >
      <template v-slot:right v-if="item && !loading" >
        <v-btn
        @click="copy"
        icon
        title="Скопировать"
      >
        <v-icon small>fa-copy</v-icon>
      </v-btn>

        <v-btn
          :href="previewUrl"
          target="_blank"
          title="Превью"
          icon
          class="mr-4"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>

        <v-btn
          right
          color="success"
          :loading="saving"
          @click.prevent="handleSubmit(save)"
        >
          <v-icon small>mdi-content-save</v-icon>
          Сохранить
        </v-btn>
      </template>

      <v-card>
        <v-form v-if="!loading && item">
          <v-tabs v-model="tab" background-color="primary" dark>
            <v-tab key="info">Инфо</v-tab>
            <v-tab key="photo">Фото</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item key="info">
              <v-card flat>
                <v-card-text>
                  <v-select
                    v-model="item.status"
                    :items="statuses"
                    label="Статус"
                  >
                  </v-select>

                  <validation-provider
                    v-slot="{ errors }"
                    name="Название"
                    rules="required"
                  >
                    <v-text-field
                      v-model="item.name"
                      label="Название"
                      :error-messages="errors"
                      required
                    ></v-text-field>
                  </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  name="Uri"
                  rules="required"
                >
                  <slug-editor
                    name="uri"
                    class="mb-12 mt-4"
                    :input="item.name"
                    label="URL-адрес статьи"
                    placeholder="URL-адрес статьи"
                    v-model="item.slug"
                    :error="errors[0]"
                    persistent-hint
                    hide-details="auto"
                    :path="publicUrl"
                  />
                </validation-provider>

                  <div>
                    <div class="body-1">Описание</div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Описание"
                      rules="required"
                    >
                      <v-input
                        :value="item.description"
                        :error-messages="errors"
                        required
                      >
                        <quill
                          v-model="item.description"
                          :options="quillToolbar"
                        >
                        </quill>
                      </v-input>
                    </validation-provider>
                  </div>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="photo">
              <v-card flat>
                <v-card-text>
                  <v-btn @click="choosePhoto" class="mr-1">{{ item.photoId ? 'Изменить' : 'Выбрать' }}</v-btn>
                  <v-btn color="error" v-if="item.photo" @click="removePhoto">Удалить</v-btn>

                  <v-img :src="item.photo.url" v-if="item.photo" max-width="1024" contain class="mt-2"/>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card>
    </item-edit-view>
  </ValidationObserver>
</template>

<script>
import symptomsService from '../../services/symptoms'
import createOrUpdateViewMixin from '../../mixins/createOrUpdateView'
import { QUILL_BASIC, QUILL_EXTENDED } from '../../components/elements/quilljs/configs'
import {STATUS_LABELS} from "./articleStatus"
import SlugEditor from '../../components/Slug/SlugEditor.vue'
import WhatDoWeTreatItem from './WhatDoWeTreatItem.vue'

export default {
  extends: WhatDoWeTreatItem,
  components: {
    SlugEditor
  },
  mixins: [createOrUpdateViewMixin(symptomsService)],
  data () {
    return {
      quillToolbar: QUILL_EXTENDED,
      tab: null,
      statuses: STATUS_LABELS,
      uuid: this.$route.params.id
    }
  },
  computed: {
    api () {
      return symptomsService
    },
    id () {
      return this.item.id
    },
    slug () {
      return this.item.slug
    }
  },
  methods: {
    choosePhoto () {
      this.photoModal = this.openPhotoModal()
      this.photoModal.$once('choose', (image) => {
        this.item.photo = image
      })
    },
    removePhoto () {
      this.item.photo = null
    },
    async copy () {
      await symptomsService.copy(this.uuid)
    }
  }
}
</script>

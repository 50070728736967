<script>
import status from './articleStatus'
export default {
  computed: {
    api () {
      throw Error('Api not set')
    },
    slug () {
      throw Error('Slug not set')
    },
    id () {
      throw Error('Id not set')
    },
    previewUrl () {
      const previewPath = this.api.getPreviewPath(this.id)
      return this.item.status === status.DRAFT
        ? this.getWebsiteUrl(previewPath)
        : this.publicUrl
    },
    publicUrl () {
      const publicPath = this.api.getPublicPath(this.slug)
      return this.getWebsiteUrl(publicPath)
    }
  },
}
</script>

import api from '../utils/api'
import omit from "lodash/omit"
import get from 'lodash/get'

const BASE_URL = 'what-do-we-treat/symptoms'

export default {
  get (slug) {
    return api.get(`${BASE_URL}/${slug}`)
  },
  getAll (params) {
    return api.get(BASE_URL, { params: params })
  },
  update (symptomId, symptom) {
    return api.patch(`${BASE_URL}/${symptomId}`, symptom)
  },
  create (symptom) {
    return api.post(BASE_URL, symptom)
  },
  remove (symptomId) {
    return api.delete(`${BASE_URL}/${symptomId}`)
  },
  copy(uuid) {
    return api.post(`${BASE_URL}/${uuid}/copy`)
  },
  createModel () {
    return {
      status: 'draft', // fixme: use const
      name: null,
      slug: null,
      description: null,
      photo: null
    }
  },
  toDto (entity) {
    let n = omit(entity, ['photo'])
    n.photoId = get(entity, 'photo.id', null)
    return n
  },
  getPreviewPath (id) {
    return `/what-do-we-treat/symptoms/${id}`
  },
  getPublicPath (slug) {
    return `/what-do-we-treat/symptoms/${slug}`
  }
}
